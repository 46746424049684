<template>
  <div id="aboutYanfa clearfix">
    <!--头部导航-->
    <div style="z-index:9999;position:fixed;top:0;left: 0;">
      <baseNav></baseNav>
    </div>

    <!--内容-->
    <div class="aboutYanfaCon clearfix">
      <!--内容头部-->
      <div class="commenHead aboutYanfaHead clearfix" style="height:110.333vw">
        <div class="about_tit">
<!--          <p  v-if="yanfaData.title&&yanfaData.title.length>0" class="animated bounceInLeft" v-html="yanfaData.title[0].title"></p>-->
          <p v-if="yanfaData.title&&yanfaData.title.length>0&&yanfaData.title[0].title" class="animated bounceInLeft about_tit_one" v-html="yanfaData.title[0].title"
           style="width: 66.667vw;height: 25.417vw;font-size: 6.944vw;font-family: Montserrat;font-weight: 300;color: #20BFD0;line-height: 8.472vw;"
          ></p>
          <p v-if="yanfaData.title&&yanfaData.title.length>0&&yanfaData.title[0].content"
             class="animated bounceInLeft about_tit_two"
             v-html="yanfaData.title[0].content"
          style="color:#ddd;font-family: Montserrat; font-weight: 300;line-height: 4.028vw;font-size: 3.333vw;"
          ></p>
        </div>
        <div class="about_tit_text">
<!--          <p class="animated bounceInLeft">专注于激光介入医疗器械及相关<br>-->
<!--            技术的研发</p>-->
        </div>
        <div class="about_img">
          <div class="right_grid ">
            <img src="../assets/images/pic_grid_r.png" alt="">
          </div>
          <div class="left_grid " style="height: auto;">
            <img src="../assets/images/pic_grid_l.png" alt="">
          </div>
        </div>
      </div>


      <!--公司介绍-->
      <div class="yf_introduce clearfix">
        <div class="backimg">
          <img src="../assets/images/pic_blue_light.png" alt="">
        </div>

        <div class="intro_cont">
          <div class="introBox introBox1">
            <div class="toDw" >
              <p class="tit" v-if="yanfaData.ipa&&yanfaData.ipa.length>0&&yanfaData.ipa[0]" v-html="yanfaData.ipa[0].title"></p>
              <p class="sub" v-if="yanfaData['ipa_values']&&yanfaData['ipa_values'].length>0&&yanfaData['ipa_values'][0]" v-html="yanfaData['ipa_values'][0].content"></p>
              <p class="con" v-if="yanfaData['ipa_values']&&yanfaData['ipa_values'].length>0&&yanfaData['ipa_values'][1]" v-html="yanfaData['ipa_values'][1].content"></p>
            </div>
          </div>

          <div class="introBox introBox2">
            <div class="toDw" >
              <p class="tit" v-if="yanfaData['fiber_optic_catheter']&&yanfaData['fiber_optic_catheter'].length>0&&yanfaData['fiber_optic_catheter'][0]" v-html="yanfaData['fiber_optic_catheter'][0].title"></p>
              <p class="sub" v-if="yanfaData['fiber_optic_catheter_values']&&yanfaData['fiber_optic_catheter_values'].length>0&&yanfaData['fiber_optic_catheter_values'][0]" v-html="yanfaData['fiber_optic_catheter_values'][0].content"></p>
              <p class="con" v-if="yanfaData['fiber_optic_catheter_values']&&yanfaData['fiber_optic_catheter_values'].length>0&&yanfaData['fiber_optic_catheter_values'][1]" v-html="yanfaData['fiber_optic_catheter_values'][1].content"></p>
            </div>
          </div>

          <div class="introBox introBox3" style="border-radius:0">
            <div class="toDw"  style="bottom:0vw">
              <p class="tit" v-if="yanfaData['artificial_intelligence_technology']&&yanfaData['artificial_intelligence_technology'].length>0&&yanfaData['artificial_intelligence_technology'][0]" v-html="yanfaData['artificial_intelligence_technology'][0].title"></p>
              <p class="sub" v-if="yanfaData['artificial_intelligence_technology_values']&&yanfaData['artificial_intelligence_technology_values'].length>0&&yanfaData['artificial_intelligence_technology_values'][0]" v-html="yanfaData['artificial_intelligence_technology_values'][0].content"></p>
              <p class="con" v-if="yanfaData['artificial_intelligence_technology_values']&&yanfaData['artificial_intelligence_technology_values'].length>0&&yanfaData['artificial_intelligence_technology_values'][1]" v-html="yanfaData['artificial_intelligence_technology_values'][1].content" ></p>
            </div>
          </div>

          </div>
        <div class="multimodalityTechnology" >
          <p class="tit"  v-if="yanfaData['multimodality_technology']&&yanfaData['multimodality_technology'].length>0&&yanfaData['multimodality_technology'][0]" v-html="yanfaData['multimodality_technology'][0].title"></p>
          <p class="sub" v-if="yanfaData['multimodality_technology']&&yanfaData['multimodality_technology'].length>0&&yanfaData['multimodality_technology'][0]" v-html="yanfaData['multimodality_technology'][0].content"></p>

            <div class="techBox"  v-show="yanfaData['multimodality_technology_values']&&yanfaData['multimodality_technology_values'].length>0">
            <div class="swiper-container mySwiper"  >
              <div class="swiper-wrapper" style="padding:0 5.555vw;" > 
                <div class="swiper-slide" v-for="(item,index) in yanfaData['multimodality_technology_values']" :key="index" >
                  <div >

                      <img v-lazy="item['pic_image']" alt="" @click="toShowPic(index)">
                      


                    <p class="tech_tit" v-html="item.title"></p>
                    <p class="tech_con" v-html="item.content"></p>
                  </div>
                </div>
                <div class="swiper-slide">
                </div>
              </div>
            </div>
          </div>
        </div>
         
        <!-- 遮罩弹出 -->
          <van-overlay :show="isMaskShow" @click="closePic">

            <div class="picWrapper" >
              <div class="swiper-container mySwiper">
                <div class="swiper-wrapper" >
                  <div class="swiper-slide swiperItem" v-for="(item,index) in allListPic" :key="index" >
                      <img v-lazy="item.pic_image" alt="">
                  </div>
                </div>
              </div>
            </div>


          </van-overlay>

            






      </div>
      <!--创新成果-->
      <div class="collabration">
        <div class="topLine"></div>
        <p class="tit">Collabration with Top<br>Worldwide Medical Institutes</p>
        <ul class="con con1 clearfix">
          <li class="item" style="flex: 1;">
            <img src="http://www.vivolight.com/static/en/images/logo_mgh.png" alt="" style="">
            <p>Massachusetts General Hospital</p>
          </li>

          <li class="item clearfix" style="width: 30.694vw;flex: 3">
            <img src="http://www.vivolight.com/static/en/images/logo_erasmus.png" alt="" style="width: 30.694vw;">
            <p>Erasmus MC</p>
          </li>
        </ul>

        <ul class="con con2 clearfix">
          <li class="item" >
            <img src="http://www.vivolight.com/static/en/images/logo_301.png" alt="">
            <p>Chinese PLA General Hospital</p>
          </li>

          <li class="item" >
            <img src="http://www.vivolight.com/static/en/images/logo_fudan.png" alt="">
            <p>Fudan University，Zhongshan Hospital</p>
          </li>

          <li class="item" >
            <img src="http://www.vivolight.com/static/en/images/logo_anzhen.png" alt="">
            <p>Beijing Anzhen Hospital，Capital Medical University</p>
          </li>

          <li class="item" style="margin-left:1vw">
            <img src="http://www.vivolight.com/static/en/images/logo_xijing.png" alt="">
            <p>Xijing Hospital</p>
          </li>
        </ul>

        <ul class="con con2 clearfix" style="margin:9vw auto">
          <li class="item" >
            <img src="http://www.vivolight.com/static/en/images/logo_changhai.png" alt="">
            <p>Changhai Hospital</p>
          </li>

          <li class="item" style="margin-left:1vw">
            <img src="http://www.vivolight.com/static/en/images/logo_tangdu.png" alt="">
            <p>Tangdu Hospital</p>
          </li>

          <li class="item" style="margin-left:1vw">
            <img src="http://www.vivolight.com/static/en/images/logo_jiaoda.png" alt="">
            <p>The First Affiliated Hospital of Xi’an Jiaotong University</p>
          </li>

          <li class="item" style="margin-left:1vw">
            <img src="http://www.vivolight.com/static/en/images/logo_hayida.png" alt="">
            <p>The Second Affiliated Hospital of Harbin Medical University</p>
          </li>
        </ul>

        <ul class="con con2 clearfix" style="margin-bottom: 25vw;">
          <li class="item" >
            <img src="http://www.vivolight.com/static/en/images/logo_zhongkeyuan.png" alt="">
            <p>Chinese Academy of Sciences</p>
          </li>

          <li class="item" style="margin-left:1vw">
            <img src="http://www.vivolight.com/static/en/images/logo_xinxueguan.png" alt="">
            <p>National Center for Cardiovascular Diseases</p>
          </li>

          <li class="item" style="margin-left:1vw">
            <img src="http://www.vivolight.com/static/en/images/logo_qinghua.png" alt="">
            <p>Tsinghua University</p>
          </li>

          <li class="item" style="margin-left:1vw">
            <img src="http://www.vivolight.com/static/en/images/logo_hk.png" alt="">
            <p>Hongkong University</p>
          </li>
        </ul>


      </div>
      <!-- footer-->
      <Copyright></Copyright>
    </div>

  </div>
</template>

<script>
import baseNav from "./baseNav";
import Copyright from "@/component/Copyright";
import {GetIndexDoc} from "@/api/api";
import Swiper from "swiper";
export default {
  data(){
    return{
      yanfaData:[],
      // 储存图片
      allListPic:[],
      //轮播遮罩层显示
      isMaskShow: false,
    }
  },
  mounted() {
    //获取关于研发页面banner数据
    this.toGetIndexDoc(3)
    //发展历程目录
    new Swiper('.multimodalityTechnology .techBox .mySwiper', {
      slidesPerView: 1.2,
      observer: true,//修改swiper自己或子元素时，自动初始化swiper
      observeParents: true,//修改swiper的父元素时，自动初始化swiper
    })
  },
  methods:{
    //获取关于研发页面banner数据
    toGetIndexDoc(data){
      let _this=this
      GetIndexDoc(data)
          .then(r=>{
            let res=r.data
            if(res.status!=1){
              _this.yanfaData=[]
              return
            }
            console.log(res)
            _this.allListPic = res.data['multimodality_technology_values'];
            console.log(_this.allListPic);
            console.log('----------------------')
            let apiIndex=res.data
            _this.yanfaData=apiIndex
            console.log(apiIndex);
          })
    },
    
   
    // 显示遮罩层图片
    toShowPic(i) {
      new Swiper('.picWrapper .mySwiper',{
        loop: false,
        observer: true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,//修改swiper的父元素时，自动初始化swiper
        autoplay:false,
        initialSlide :i,
      })
      this.isMaskShow = !this.isMaskShow;
      // console.log(index);
      console.log('-----------');
      // console.log(this.allListPic[index].pic_image);
      // this.allListPic[index].pic_image
      // this.allListPic.index 
    },
    //关闭遮罩层图片
    closePic() {
      this.isMaskShow = false;
       let _this=this
      setTimeout(function (){
        _this.clickPicList=[]
      })
    }

  },
  components: {
    baseNav,
    Copyright
  }

}
</script>
<style lang="less" scoped>
#aboutYanfa {
  background-color: #071D42;
}
.aboutYanfaCon {
  padding-top: 13.888vw;
  background-color: #071D42;
  width: 100%;

  .aboutYanfaHead{
    background: url("../assets/images/pic_banner_creativity.png") no-repeat -56.94vw 1.666vw;
    background-size: 180%;
    padding-top:15.277vw;
  }

  .yf_introduce{
    position: relative;
    padding:0 6.666vw;
    .backimg{
      width: 60%;
      height: 131.388vw;
      position: absolute;
      left: 0;
      top:55.555vw;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .intro_cont{
      margin-top: 10vw;
      .introBox1{
        margin-bottom: 3.333vw;
        background: url("http://www.vivolight.com/static/en/icon/pic_innovation_p1@2x.jpg") no-repeat top center;
        background-size: cover;
      }
      .introBox2{
        margin-bottom: 3.333vw;
        background: url("http://www.vivolight.com/static/en/icon/pic_innovation_p2@2x.jpg") no-repeat top center;
        background-size: cover;
      }
      .introBox3{

        background: url("../assets/images/pic_innovation_p3@2x.png") no-repeat top center ;
        background-size: cover;
        // background-image: linear-gradient(to bottom,#061837 80%,#061c41 110%);

        // background: no-repeat center top / 100% 100%;
        // background-image: linear-gradient(to top, rgba(7, 29, 65) -0%, rgba(255, 255, 255, 0) 100%), url("../assets/images/pic_innovation_p3@2x.png");
      }
      .introBox{
        width: 86.668vw;
        height: 97.222vw;
        padding: 5.555vw;
        box-sizing: border-box;
        position: relative;
        border-radius: 4vw;
        p{
          text-align: left;
          width: 75.558vw;
        }
        .toDw{
          position: absolute;
          bottom: 5.555vw;
          .tit{
            font-size: 5.556vw;
            font-family: Montserrat;
            font-weight: 300;
            color: #20BFD0;
            line-height: 6.805vw;
            margin-bottom: 2.222vw;
          }
          .sub{
            // font-size: 2.777vw;
            font-size: 3.333vw;
            font-family: Montserrat;
            font-weight: 600;
            color: #20BFD0;
            line-height: 4.028vw;
            margin-bottom: 1.527vw;
          }
          .con{
            // font-size: 2.777vw;
            font-size: 3.333vw;
            font-family: Montserrat;
            font-weight: 300;
            color: #ddd;
            line-height: 4.028vw;
          }
        }
      }
     }
    .multimodalityTechnology{

      width: 86.668vw;
      //height: 126.5vw;
      padding-bottom: 5.555vw;
      padding-top: 10vw;
      box-sizing: border-box;
      border-radius: 0 0 3.333vw 3.333vw;
      // background: linear-gradient(180deg, rgba(6, 23, 53, 0) 0%, #071836 100%);
      background: #071836;
      .tit{
        font-size: 3.333vw;
        font-family: Montserrat;
        font-weight: 600;
        color: #20BFD0;
        line-height:4.0277vw;
        margin-bottom: 1.25vw;
        margin-left: 5.555vw;
      }
      .sub{
        font-size: 3.055vw;
        font-family: Montserrat;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 3.75vw;
        margin-bottom: 5.416vw;
        margin-left: 5.555vw;
      }
      .techBox{
        >div{
          img{
            width: 59.166vw;
            height: 35vw;
            margin-bottom: 5.555vw;
          }
          p{
            width: 59.166vw;
            // width: 100%;
            // font-size: 2.777vw;
            font-size: 3.333vw;
            color: #FFFFFF;
            line-height: 3.333vw;
          }
          .tech_tit{
            width: 65vw;
            font-size: 3.333vw;
            font-family: Montserrat;
            font-weight: 600;
            line-height: 3.333vw;
            margin-bottom: 2.222vw;
          }
          .tech_con{
            font-size: 3.333vw;
            line-height: 4.722vw;
            font-family:Montserrat;
            font-weight: 300;
            color:#ddd;
          }
        }
      }
    }

.picWrapper{
    margin-top: 50vh;
    transform: translateY(-50%);
    .swiperItem{
      position: relative;
      img{
        width: 100%;
      }
    }
  }

  }
  //创新成果
  .collabration{
    position: relative;
    margin-top: 11.111vw;
    padding:0 6.666vw;
    //padding-bottom:13.888vw;
    .tit{
      font-size: 5.555vw;
      font-family: Montserrat;
      font-weight: 300;
      color: #20BFD0;
      line-height: 6.805vw;
      margin: 2.777vw 0 9.722vw 0;
    }
    .con{
      .item{
        position: relative;
        display: inline-block;
        //min-height: 20vw;
        //margin-right: 2.777vw;
        img{
          height: 11.944vw;
        }
        p{
          position: absolute;
          width: 100%;
          top: 13vw;
          margin-top: 1.666vw;
          font-size: 2.5vw;
          font-family: Montserrat;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 3.0556vw;
        }
      }
    }
    .con1{
      display: flex;
      height: 24vw;
      .item{
        justify-content: left;
      }
    }
    .con2{
      display: flex;
      height: 24vw;
      .item{
        flex: 1;
        justify-content: space-between;
      }
    }

  }
}
</style>